.DocumentUpload {
    min-height: calc(100vh - 120px);
    max-height: 100%;
    background-color: #395395;
    padding-bottom: 45px;
    padding: 60px 85px;
    /* height: 735px; */
}

/* Header  ----------------------------------------------------------------  */

.doc-upload-header-container {
    display: flex;
    justify-content: flex-start;
    flex-flow: column;
}


.company-logo {
    width: 250px;
    margin-bottom: 40px;
}

/* Header Text  ----------------------------------------------------------------- */
.doc-text-container {
    display: flex;
    justify-content: flex-start;
    max-width: 700px;
}

.doc-text {
    text-align: left;
    font-weight: 400;
    font-size: 30px;
    color: #FFFFFF;
    /* opacity: .5; */
}

/* Upload Button  ----------------------------------------------------------------  */

.doc-upload-button-container {
    display: flex;
    justify-content: flex-end;
}


.doc-upload-button {
    padding: 10px 50px;
    font-size: 24px;
    font-weight: 400;
    color: #395395;
    opacity: .6;
    background: #FFFFFF;
    border-radius: 30px;
    border: none;
}


.doc-upload-button:hover {
    background-color: #E7E5E5;
    transition: .3s;
    outline: none;
    cursor: pointer;
}

.doc-upload-button:focus {
    outline: none;
}

/* active button ----- */

.doc-upload-button-active {
    padding: 10px 50px;
    font-size: 24px;
    font-weight: 400;
    color: #395395;
    background: #FFFFFF;
    border-radius: 30px;
    border: none;
}

.doc-upload-button-active:hover {
    background-color: #E7E5E5;
    transition: .3s;
    outline: none;
    cursor: pointer;
}

.doc-upload-button-active:focus {
    outline: none;
}


/* Upload Document Input  ----------------------------------------------------------------  */

.doc-input-container {
    margin: 100px 0px;
    border: 5px solid #9BA9CA;
    border-style: dashed;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
}

/* bordered drop area */
.filepond--panel-root {
    border: 4px dashed #9BA9CA;
    opacity: 0;
}



.filepond--root {
    height: auto;
}


.filepond--item-panel {
    background-color: red;

}


/* Input Placeholder (document upload) ----------------------------------------------------------------  */

.filepond--drop-label {
    right: 0;
    color: #9BA9CA !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 25px;
}


.filepond--drop-label label {
    cursor: default;
    font-size: 20px !important;
    font-weight: 400;
    text-align: center;
    line-height: 1.5;
}

.filepond--file {
    background-color: #FFFFFF;
    color: #395395 !important;
    font-size: 20px !important;
}



/*----------------------------------------------------------------  */
/*----------------------------------------------------------------  */

@media screen and (max-width: 992px) {

}

@media screen and (max-width: 767px) {

    .DocumentUpload {
        min-height: calc(100vh - 120px);
        max-height: 100%;
        background-color: #395395;
        padding-bottom: 45px;
        padding: 60px 25px;
    }

    .doc-upload-button-container {
        display: flex;
        justify-content: center;
    }

    .filepond--drop-label label {
        font-size: 16px !important;
    }

}
